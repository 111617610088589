/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import MainLayout from '../views/layouts/MainLayout';
import SEO from '../views/components/SEO';
import Parallax from '../views/components/Parallax';
import Gallery from '../views/components/Masonry';
import { PRODUCT_TYPE_WRAP } from '../constants/products';

const GiftWrapPage = ({
    pageContext: { locale, originalPath },
    location,
    data,
}) => {
    const images = data.images.edges;
    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.wrap.headerTitle'
                description='pages.wrap.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
            />
            <div className='page-bg'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-emballage-cadeau-groupe-editor'
                    overlay='light'
                >
                    <div className='page-intro gallery'>
                        <div className='container'>
                            <h1>
                                <FormattedMessage id='pages.wrap.title' />
                            </h1>
                            <p>
                                <FormattedMessage id='pages.wrap.description' />
                            </p>
                            <p>
                                <FormattedMessage id='pages.wrap.description2' />
                            </p>
                        </div>
                    </div>
                </Parallax>
                <div className='page-content gallery'>
                    <div className='container'>
                        <Gallery
                            list={images}
                            fluid
                            originalPath={originalPath}
                            type={PRODUCT_TYPE_WRAP}
                        />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

GiftWrapPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default GiftWrapPage;

// cover 1 : IMG_5178M.jpg
// cover 2 : IMG_9587.jpg

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "IMG_9587.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "IMG_9587.jpg" }
        ) {
            ...SeoImages
        }
        images: allFile(filter: { sourceInstanceName: { eq: "giftWrap" } }) {
            ...MasonryImages
        }
    }
`;
